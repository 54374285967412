<template>
  <div>
    <center>
       <img src="elementos/logo.png" class="rounded" alt="Image" :style="isMobile ? 'width:85%;' : ''">
       <br>
           <p v-if="isMobile" class=" text-white mt-5 text-center" style="font-size:20px;"><b>¡SON AQUELLOS QUE <br> SE REINVENTAN!</b></p>
       <br v-if="!isMobile"><br v-if="!isMobile">
       <img v-if="!isMobile" src="elementos/aquellos.png" class="rounded" alt="Image" :style="isMobile ? 'width:90%;' : ' margin-top:-50px;'">
   

        <b-row class="justify-content-center mt-5">
         <b-col cols="10" md="5">
            <p class="text-info" :style="isMobile ? 'font-size:15px;' : 'font-size:20px;'">
              <b>
                Sabemos que cuentas con todas las aptitudes para ser un agente inquebrantable, durante la misión estamos evaluando el trabajo en equipo, el conocimiento
                del negocio y especialmente el cumplimiento constante de las cuotas comerciales.

                <br><br><br>

                Tu esfuerzo y dedicación te harán  parte de este grupo de élite. <br>
                La misión ha comenzado.
               </b>
            </p>
            <img src="elementos/flecha.png" class="rounded" alt="Image" :style="isMobile ? 'width:15%;' : 'width:5%;'">
            <b-row class="justify-content-center" >
              <b-col cols="6" >
                <v-btn  color="#5cb617" class="mt-5"   x-large block  style="text-transform:capitalize;" @click="aceptaBienvenida()" >
                  <h4 style="color:#000;" class="text-center mt-2"><b>START</b> <b-spinner v-if="loader" small></b-spinner> </h4>
                </v-btn>
              </b-col>
            <!-- <v-btn class="mt-5"    color="#5cb617" @click="aceptaBienvenida()" :style="isMobile ? 'width:80%;':'width:50%;'"><strong>START</strong> <b-spinner v-if="loader" small></b-spinner> </v-btn> -->
             </b-row>
          </b-col>
        </b-row>
     </center>
  </div>
</template>

<script>
export default {
  name: 'Inicio',
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    },
  },
  methods:{
    aceptaBienvenida(){
      this.loader = true;
      var url= 'home/bienvenida?id='+ this.user.id;
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                this.loader = false;
                this.$router.push("/");       
            }
        );
    }
  }
}
</script>